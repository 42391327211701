import Vue from 'vue'

// axios
import axios from 'axios'

const that = Vue.prototype

if (that.state.token) {
    // console.log('I have token : ', that.state.token)
    axios.defaults.headers.common['Authorization'] = that.state.token
}

// axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.baseURL = window.location.hostname.includes('aviinex') ? 'https://aviinex.xyz/api'
    : window.location.hostname.includes('whalebitex') ? 'https://whalebitex.org/api'
        : window.location.hostname.includes('beebitex') ? 'https://beebitex-1.com/api'
            : window.location.hostname.includes('mobinex') ? 'https://mobinex.xyz/api'
                : window.location.hostname.includes('vistadev') ? 'https://vistadev.xyz/api'
                    : window.location.hostname.includes('radin') ? 'https://vistadev.xyz/api'
                        : window.location.hostname.includes('tradex') ? 'https://tradex.work/api'
                            : window.location.hostname.includes('btrio') ? 'https://btrio.click/api'
                                : window.location.hostname.includes('ramzbaz') ? 'https://itarz.net/api'
                                    : window.location.hostname.includes('exirex') ? 'https://exirex.org/api'
                                        : window.location.hostname.includes('privex') ? 'https://privex.click/api'
                                            : window.location.hostname.includes('odinex') ? 'https://odinex.net/api'
                                                : window.location.hostname.includes('bitmah') ? 'https://maahbit.ir/api'
                                                    : window.location.hostname.includes('dalyacoin') ? 'https://dalyacoin.info/api'
                                                        : window.location.hostname.includes('8cobit') ? 'https://8cobit.live/api'
                                                            : window.location.hostname.includes('darax') ? 'https://darax.org/api'
                                                                : window.location.hostname.includes('khurcoin') ? 'https://khurcoin.click/api'
                                                                    : window.location.hostname.includes('bitfelex') ? 'https://bitfelex.net/api'
                                                                        : window.location.hostname.includes('grandbitex') ? 'https://grandbitex.click/api'
                                                                            : window.location.hostname.includes('moditra') ? 'https://moditra.net/api'
                                                                                : window.location.hostname.includes('derakht') ? 'https://derakht.xyz/api'
                                                                                    : window.location.hostname.includes('buysells') ? 'https://buysells.info/api'
                                                                                        : window.location.hostname.includes('digi-x') ? 'https://sarafi-digital.com/api'
                                                                                            : window.location.hostname.includes('hakarex') ? 'https://hakarex.net/api'
                                                                                                : window.location.hostname.includes('bitex') ? 'https://bitexmarket.net/api'
                                                                                                    : window.location.hostname.includes('haftramz') ? 'https://7arz.com/api'
                                                                                                        : window.location.hostname.includes('7ramz') ? 'https://7arz.com/api'
                                                                                                            : window.location.hostname.includes('haftarz') ? 'https://7arz.com/api'
                                                                                                                : window.location.hostname.includes('novin') ? 'https://novin.click/api'
                                                                                                                    : window.location.hostname.includes('radfinex') ? 'https://novin.click/api'
                                                                                                                        : window.location.hostname.includes('zarcoin') ? 'https://zzarcoin.xyz/api'
                                                                                                                            : window.location.hostname.includes('192.168') ? 'http://localhost:8098/api'
                                                                                                                                : 'https://vistadev.xyz/api'

axios.defaults.baseURL = 'https://vistadev.xyz/exchange/api'
if (process.env.NODE_ENV === 'development') {
// axios.defaults.baseURL = 'http://localhost:8080/api'
}

axios.interceptors.response.use(
    (res) => {
        that.state.loading = false

        // console.log('result : ', res)

        return res;
    },
    async (err) => {
        that.state.loading = false

        const res = err.response || err.toJSON()

        // console.error('result : ', res);

        /*console.warn(
            //     'config : ', err.config,
            //     'code : ', err.code,
            'request : ', err.request,
            //     'response : ', err.response,
            //     'isAxios : ', err.isAxiosError,
            //     'json : ', err.toJSON()
        )*/

        if (res?.config?.responseType === 'blob') {
            const vm = that
            let reader = new FileReader();
            reader.onload = function (e) {
                let test = JSON.parse(e.target.result);
                vm.$error('', vm.$errorHandeling[test.message])
            };
            reader.readAsText(err.response.data);
            return Promise.reject(err);
        }

        if (res?.data?.message?.includes('JWT') || res?.data?.message?.includes('Invalid Token')) {
            that.$logout()
            return Promise.reject(err);
        }

        if (['UNAUTHORIZED'].includes(res?.data?.status)) {
            if (that.$errorHandeling[err.response.data.message]) {
                that.$error(that.$errorHandeling[err.response.data.message])
            }
            return Promise.reject(err);
        }

        if (['Validation Error'].includes(res?.data?.message)) {
            return Promise.reject(err);
        }

        if (res?.data?.message === 'Market Is Inactive') {
            that.$error('بازار غیر فعال است')
            return Promise.reject(err);
        }

        const text = that.$errorHandeling[res?.data?.message]
            || that.$errorHandeling[res?.data?.status]
            || res?.data?.errors
            || res?.data?.error
            || res?.message

        if (res && that.$errorHandeling[err.response.data.message] || that.$errorHandeling[status]) {
            that.$error('',text)
        }else if (res && that.$errorHandeling[err.response.data.status]){
            that.$error(text,err.response.data.message)
        }else {
            that.$error(err.response.data.status,text)
        }

        return Promise.reject(err);
    },);

that.$axios = axios

let func = async (config) => {
    try {
        const res = await axios(config)
        return [res, null]
    } catch (e) {
        return [null, e]
    }
}
const props = {
    get: async (url, config) => {
        try {
            const res = await axios.get(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    post: async (url, data, config) => {
        try {
            const res = await axios.post(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    put: async (url, data, config) => {
        try {
            const res = await axios.put(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
}
for (let prop in props) {
    func[prop] = props[prop];
}
that.$http = func;

export default axios
