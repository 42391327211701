import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import Ripple from "vue-ripple-directive";
import store from './store'

// Global
import './global-components'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/vue-bootstrap-plugin'
import '@/libs/state'
import '@/libs/axios'
import '@/libs/globalFunctions'
import '@/libs/globalConstants'
import '@/libs/VueDatePicker'
import '@/libs/froala-editor'

import router from './router'
import App from './App.vue'

Vue.directive('ripple', Ripple);

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')
// import Yekan font
require('@/assets/Font/Font-Yekan.css')

Vue.config.productionTip = false


new Vue({
    router,
    store,
    render: h => h(App),
    // render: h => h(() => import('@/App.vue')),
}).$mount('#app')
