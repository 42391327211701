import Vue from 'vue';

const that = Vue.prototype

that.$perivilageData = [
    {
        module: 'USERS',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'ADMINS',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'ROLES',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'DOLLARS',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: false,
        DELETE: false,
    },
    {
        module: 'WALLET',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: false,
    },
    {
        module: 'WITHDRAW',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'HISTORIES',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
    },
    {
        module: 'FAQ',
        LIST: true,
        DETAILS: false,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'USER_MANUAL',
        LIST: true,
        DETAILS: false,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'TRADES',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: true,
        DELETE: false,
    },
    {
        module: 'ORDERS',
        LIST: true,
        DETAILS: false,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'MARKET',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
]

that.$accountLevel = []

that.$subPerivilage = [
    'UPDATE',
    'LIST',
    'DELETE',
    'CREATE',
    'DETAILS',
]

that.$coins = []
that.$ethBase = ['BINANCE_COIN_BSC','DOGE_COIN']

that.$decimal = {
    BITCOIN:8,
    BITCOIN_CASH:3,
    DOGE_COIN:0,
    LITE_COIN:3,
    DASH:3,
    ETHEREUM:4,
    CLASSIC_ETHEREUM:2,
    TETHER:2,
    TOMAN:0,
    TRON:1,
    CELO:1,
    MATIC:1,
    RIPPLE:0,
    STELLAR:0,
    ELROND:2,
    BINANCE_COIN_BSC:3,
}

that.$coinFilter = []
that.$marketFilter = []

export let coinFilterArray = that.$coinFilter
export let marketFilterArray = that.$marketFilter

that.$errorHandeling = {
    BAD_REQUEST: 'اطلاعات ارسال شده اشتباه است',
    NOT_FOUND: 'اطلاعات درخواستی یافت نشد',
    FINNOTECH_NOT_COMPLETED: 'کاربر فرآیند فینوتک را تکمیل نکرده است',
    CONFLICT: 'اجازه ندارید',
    FORBIDDEN: 'دسترسی لازم را ندارید',
    CARD_NUMBER_DOESNT_MATCH_SHEBA: 'عدم تطابق شماره کارت و شماره شبا',
    EXCEL_NOT_GENERATED: 'اطلاعاتی جهت خروجی اکسل وجود ندارد',
    INCORRECT_WALLET_PASSWORD: 'رمز کیف پول اشتباه است',
    DUPLICATE_NAME: 'نام کیف پول تکراریست.',
}