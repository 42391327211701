import Vue from 'vue'
import VueRouter from 'vue-router'
import addNewRole from "@/views/Admins/addNewRole";
import data from '@/navigation/vertical/index'

// eslint-disable-next-line no-unused-vars
const that = Vue.prototype

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'Dashboard',
            component: () => import('@/views/Dashboard/Home.vue'),
            meta: {
                pageTitle: 'داشبورد',
                breadcrumb: [
                    {
                        text: 'داشبورد',
                        active: true,
                    },
                ],
            },
        },
        //
        //UserAccounts
        {
            path: '/user-accounts',
            name: 'Accounts',
            component: () => import('@/views/UserAccounts/Accounts'),
            meta: {
                pageTitle: 'کاربران سایت',
                breadcrumb: [
                    {
                        text: 'لیست کاربران',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/show-accounts/:id',
            name: 'show-accounts',
            props: true,
            component: () => import('@/views/UserAccounts/showAccount'),
            meta: {
                pageTitle: 'کاربران سایت',
                breadcrumb: [
                    {
                        text: 'لیست کاربران',
                        active: false,
                        to: '/user-accounts'
                    },
                    {
                        text: 'مشاهده کاربر',
                        active: true,
                    },
                ],
            },
        },
        //
        //panel
        {
            path: '/admin-accounts',
            name: 'admin-accounts',
            component: () => import('@/views/Admins/AdminAccounts'),
            meta: {
                pageTitle: 'مدیران سایت',
                breadcrumb: [
                    {
                        text: 'مدیریت سامانه',
                        active: true,
                    },
                    {
                        text: 'لیست مدیران',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-profile/:id',
            name: 'admin-profile',
            props: true,
            component: () => import('@/views/Admins/AdminProfile'),
            meta: {
                pageTitle: 'پروفایل مدیر',
                breadcrumb: [
                    {
                        text: 'مدیریت سامانه',
                        active: true,
                    },
                    {
                        text: 'لیست مدیر',
                        active: false,
                        to: '/admin-accounts'
                    },
                    {
                        text: 'جزییات مدیر',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-profile',
            name: 'admin-profile',
            component: () => import('@/views/Admins/AdminProfile'),
            meta: {
                pageTitle: 'پروفایل',
            },
        },
        {
            path: '/roles',
            name: 'admin-roles',
            component: () => import('@/views/Admins/Roles'),
            meta: {
                pageTitle: 'نقش ها',
                breadcrumb: [
                    {
                        text: 'مدیریت سامانه',
                        active: true,
                    },
                    {
                        text: 'لیست نقش ها',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/roles/add-role',
            name: 'add-role',
            component: addNewRole,
            meta: {
                pageTitle: 'افزودن نقش',
                breadcrumb: [
                    {
                        text: 'مدیریت سامانه',
                        active: true,
                    },
                    {
                        text: 'لیست نقش ها',
                        active: false,
                        to: '/roles',
                    },
                    {
                        text: 'افزودن نقش',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/roles/show-role/:id',
            name: 'show-role',
            props: true,
            component: addNewRole,
            meta: {
                pageTitle: 'مدیریت نقش',
                breadcrumb: [
                    {
                        text: 'مدیریت سامانه',
                        active: true,
                    },
                    {
                        text: 'لیست نقش ها',
                        active: false,
                        to: '/roles',
                    },
                    {
                        text: 'مدیریت نقش',
                        active: true,
                    },
                ],
            },
        },
        //
        //dollar
        {
            path: '/dollar',
            name: 'dollar',
            component: () => import('@/views/DollarPrice'),
            meta: {
                pageTitle: 'تاریخچه قیمت دلار',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'مدیریت دلار',
                        active: true,
                    },
                ],
            },
        },
        //
        //cryptocurrency
        {
            path: '/cryptocurrency',
            name: 'cryptocurrency',
            component: () => import('@/views/CryptoCurrency/CryptoCurrency'),
            meta: {
                pageTitle: 'مدیریت رمزارزهای پلتفرم معاملاتی',
                breadcrumb: [
                    {
                        text: 'مدیریت رمزارز',
                        active: true,
                    },
                    {
                        text: 'رمزارز های پلتفرم معاملاتی',
                        active: true,
                    },
                ],
            },
        },
        //
        {
            path: '/exchange-cryptocurrency',
            name: 'exchange-cryptocurrency',
            component: () => import('@/views/CryptoCurrency/ExchangeCryptoCurrency'),
            meta: {
                pageTitle: 'مدیریت رمزارز های صرافی',
                breadcrumb: [
                    {
                        text: 'مدیریت رمزارز',
                        active: true,
                    },
                    {
                        text: 'رمزارز های صرافی',
                        active: true,
                    },
                ],
            },
        },
        //
        //gift-cards
        {
            path: '/gift-cards',
            name: 'gift-cards',
            component: () => import('@/views/Accounting/GiftCards/GiftCards'),
            meta: {
                pageTitle: 'درخواست های کارت',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'درخواست های ارسال کارت',
                        active: true,
                    },
                ],
            },
        },
        //
        //accountLevels
        {
            path: '/account-levels',
            name: 'account-levels',
            component: () => import('@/views/AccountLevels/AccountLevels'),
            meta: {
                pageTitle: 'سطوح کاربری',
                breadcrumb: [
                    {
                        text: 'کاربران',
                        to: '/accounts',
                        active: false,
                    },
                    {
                        text: 'مدیریت سطوح کاربری',
                        active: true,
                    },
                ],
            },
        },
        //
        //accounting
        {
            path: '/wallets',
            name: 'wallets',
            component: () => import('@/views/Accounting/Wallets'),
            meta: {
                pageTitle: 'لیست کیف پول های پلتفرم معاملاتی',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'مدیریت کیف پول ها',
                        active: true,
                    },
                    {
                        text: 'کیف پول های پلتفرم معاملاتی',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/exchange-wallets',
            name: 'exchange-wallets',
            component: () => import('@/views/Accounting/ExchangeWallets'),
            meta: {
                pageTitle: 'لیست کیف پول های صرافی',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'مدیریت کیف پول ها',
                        active: true,
                    },
                    {
                        text: 'کیف پول های صرافی',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/fees',
            name: 'fees',
            component: () => import('@/views/Accounting/Fees/Fees'),
            meta: {
                pageTitle: 'لیست کارمزدهای کسب شده پلتفرم معاملاتی',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'کارمزدهای کسب شده',
                        active: true,
                    },
                    {
                        text: 'کارمزد های پلتفرم معاملاتی',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/exchange-fees',
            name: 'exchange-fees',
            component: () => import('@/views/Accounting/Fees/ExchangeFees'),
            meta: {
                pageTitle: 'لیست کارمزدهای کسب شده صرافی',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'کارمزدهای کسب شده',
                        active: true,
                    },
                    {
                        text: 'کارمزد های پلتفرم صرافی',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/withdraw-orders',
            name: 'withdraw-orders',
            component: () => import('@/views/Accounting/Withdraw'),
            meta: {
                pageTitle: 'درخواست های برداشت',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تراکنش ها',
                        active: true,
                    },
                    {
                        text: 'درخواست های برداشت',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deposit-orders',
            name: 'deposit-orders',
            component: () => import('@/views/Accounting/Deposit'),
            meta: {
                pageTitle: 'درخواست های واریز دستی صرافی',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تراکنش ها',
                        active: true,
                    },
                    {
                        text: 'درخواست واریز صرافی',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/gather-requests',
            name: 'gather-requests',
            component: () => import('@/views/Accounting/GatherRequests'),
            meta: {
                pageTitle: 'درخواست های تجمیع',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تراکنش ها',
                        active: true,
                    },
                    {
                        text: 'درخواست های تجمیع',
                        active: true,
                    },
                ],
            },
        },
        ////adresses
        {
            path: '/toman-addresses',
            name: 'toman-addresses',
            component: () => import('@/views/Accounting/Addresses/TomanWallets'),
            meta: {
                pageTitle: 'کیف پول های تومانی',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'کیف پول ها',
                        to: '/wallets',
                        active: false,
                    },
                    {
                        text: 'لیست کیف پول های تومانی',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/x-addresses',
            name: 'x-addresses',
            component: () => import('@/views/Accounting/Addresses/Addresses'),
            meta: {
                pageTitle: 'آدرس های XLM/XRP',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'کیف پول ها',
                        to: '/wallets',
                        active: false,
                    },
                    {
                        text: 'لیست آدرس های XLM/XRP',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/hd-addresses',
            name: 'hd-addresses',
            component: () => import('@/views/Accounting/Addresses/Addresses'),
            meta: {
                pageTitle: 'آدرس ها',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'کیف پول ها',
                        to: '/wallets',
                        active: false,
                    },
                    {
                        text: 'لیست آدرس های ارز های دیگر',
                        active: true,
                    },
                ],
            },
        },
        ////
        ////history
        {
            path: '/wallets/transactions',
            name: 'wallet-transactions',
            component: () => import('@/views/Accounting/Transaction/WalletTransactions'),
            meta: {
                pageTitle: 'تراکنش های شبکه',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تاریخچه',
                        active: false,
                    },
                    {
                        text: 'تراکنش های شبکه',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/wallets/other-transactions',
            name: 'other-transactions',
            component: () => import('@/views/Accounting/Transaction/OtherTransactions'),
            meta: {
                pageTitle: 'تراکنش های ارز های دیگر',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تاریخچه',
                        active: false,
                    },
                    {
                        text: 'سایر تراکنش ها',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/wallets/ethereum-transactions',
            name: 'ethereum-transactions',
            component: () => import('@/views/Accounting/Transaction/EthereumTransactions'),
            meta: {
                pageTitle: 'تراکنش های شبکه',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تاریخچه',
                        active: false,
                    },
                    {
                        text: 'تراکنش های شبکه',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/wallets/x-transactions',
            name: 'x-transactions',
            component: () => import('@/views/Accounting/Transaction/XTransactions'),
            meta: {
                pageTitle: 'تراکنش های شبکه XRP/XLM',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تاریخچه',
                        active: false,
                    },
                    {
                        text: 'تراکنش های شبکه XLM/XRP',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/wallets/tron-transactions',
            name: 'tron-transactions',
            component: () => import('@/views/Accounting/Transaction/TronTransactions'),
            meta: {
                pageTitle: 'تراکنش های شبکه ترون',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تاریخچه',
                        active: false,
                    },
                    {
                        text: 'تراکنش های شبکه ترون',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/withdraw-history',
            name: 'withdraw-history',
            component: () => import('@/views/Accounting/Transaction/withdrawHistory'),
            meta: {
                pageTitle: 'تاریخچه تراکنش ها',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تاریخچه',
                        active: false,
                    },
                    {
                        text: 'تاریخچه برداشت',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/toman-transactions',
            name: 'toman-transactions',
            component: () => import('@/views/Accounting/Transaction/TomanTransactions'),
            meta: {
                pageTitle: 'تاریخچه تراکنش ها',
                breadcrumb: [
                    {
                        text: 'مدیریت مالی',
                        active: true,
                    },
                    {
                        text: 'تاریخچه',
                        active: false,
                    },
                    {
                        text: 'تاریخچه واریز',
                        active: true,
                    },
                ],
            },
        },
        ////
        //
        //markets
        {
            path: '/markets',
            name: 'Markets',
            component: () => import('@/views/Markets/MarketIndex'),
            meta: {
                pageTitle: 'بازار ها',
                breadcrumb: [
                    {
                        text: 'بازار ها',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/markets/statistics/:id',
            name: 'statistics',
            props: true,
            component: () => import('@/views/Markets/Statistics'),
            meta: {
                pageTitle: 'نمودار ها',
                breadcrumb: [
                    {
                        text: 'لیست بازار ها',
                        to: '/markets',
                        active: false,
                    },
                    {
                        text: 'نمودار های بازار',
                        active: true,
                    },
                ],
            },
        },
        //
        //deals
        {
            path: '/deals',
            name: 'Deals',
            component: () => import('@/views/Deals/Deals'),
            meta: {
                pageTitle: 'تاریخچه معاملات',
                breadcrumb: [
                    {
                        text: 'معاملات',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/otc-trades',
            name: 'otc-trades',
            component: () => import('@/views/Deals/BnDeals'),
            meta: {
                pageTitle: 'تاریخچه معاملات',
                breadcrumb: [
                    {
                        text: 'معاملات بایننس',
                        active: true,
                    },
                ],
            },
        },
        //
        //orders
        {
            path: '/orders',
            name: 'Orders',
            component: () => import('@/views/Orders/Orders'),
            meta: {
                pageTitle: 'لیست سفارشات',
                breadcrumb: [
                    {
                        text: 'سفارشات',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/manual-orders',
            name: 'manual-orders',
            component: () => import('@/views/Orders/ManualOrders'),
            meta: {
                pageTitle: 'لیست سفارشات فعال صرافی',
                breadcrumb: [
                    {
                        text: 'سفارشات',
                        active: true,
                    },
                    {
                        text: 'سفارشات فعال صرافی',
                        active: true,
                    },
                ],
            },
        },
        //
        //Messages
        {
            path: '/messages',
            name: 'Messages',
            component: () => import('@/views/Messages/Messages'),
            meta: {
                pageTitle: 'لیست پیام ها',
                breadcrumb: [
                    {
                        text: 'پیام ها',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/chat',
            name: 'Chat',
            component: () => import('@/views/Messages/chat/Chat'),
            meta: {
                pageTitle: 'تیکت ها',
                breadcrumb: [
                    {
                        text: 'تیکت ها',
                        active: true,
                    },
                ],
                contentRenderer: 'sidebar-left',
                contentClass: 'chat-application',
            },
        },
        //
        //SiteData
        {
            name: 'HowToUse',
            path: '/how-to-use',
            component: () => import('@/views/SiteData/HowToUse')
        },
        {
            name: 'NewHelp',
            path: '/how-to-use/new',
            component: () => import('@/views/SiteData/NewHelp'),
            meta: {
                pageTitle: 'راهنمای جدید',
                breadcrumb: [
                    {
                        text: 'تنظیمات سایت',
                        active: true,
                    },
                    {
                        text: 'راهنمای استفاده',
                        active: false,
                        to: '/how-to-use'
                    },
                    {
                        text: 'ساخت راهنمای جدید',
                        active: true,
                    },
                ],
            },
        },
        {
            name: 'ShowHelp',
            path: '/how-to-use/show/:id',
            component: () => import('@/views/SiteData/NewHelp'),
            props: true,
            meta: {
                pageTitle: 'مشاهده راهنما',
                breadcrumb: [
                    {
                        text: 'تنظیمات سایت',
                        active: true,
                    },
                    {
                        text: 'راهنمای استفاده',
                        active: false,
                        to: '/how-to-use'
                    },
                    {
                        text: 'مشاهده راهنما',
                        active: true,
                    },
                ],
            },
        },
        {
            name: 'FAQ',
            path: '/faq',
            component: () => import('@/views/SiteData/FAQ'),
            meta: {
                pageTitle: 'سوالات متداول',
                breadcrumb: [
                    {
                        text: 'تنظیمات سایت',
                        active: true,
                    },
                    {
                        text: 'سوالات متداول',
                        active: true,
                    },
                ],
            },
        },
        {
            name: 'SiteInfo',
            path: '/site-info',
            component: () => import('@/views/SiteData/SiteInfo'),
            meta: {
                pageTitle: 'اطلاعات سایت',
                breadcrumb: [
                    {
                        text: 'تنظیمات سایت',
                        active: true,
                    },
                    {
                        text: 'اطلاعات سایت',
                        active: true,
                    },
                ],
            },
        },
        //
        //
        {
            name: '/test',
            path: '/test',
            component: () => import('@/views/TestComponent')
        },
        //
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                no_auth: true,
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                no_auth: true,
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    if (that.state.token && !that.state.gotten) {
        that.state.loading = true
        await that.$getUserInfo()
        let sourceData = JSON.parse(JSON.stringify(data))
        await that.$filter(sourceData)
        that.state.menuItem = sourceData
        await that.$getCoins()
    }
    // console.log('=>>>>>>>>>>>>>>>>>', that.state.userInfo)
    if (to.meta.no_auth && that.state.token)
        next('/')
    else if ((to.meta.no_auth && !that.state.token) || (!to.meta.no_auth && that.state.token))
        next()
    else if (!to.meta.no_auth && !that.state.token)
        next('/login')
})

router.afterEach((to)=>{
    localStorage.route = JSON.stringify([...that.state.viewedMenus,to.name])
    that.state.viewedMenus = [...that.state.viewedMenus,to.name]
})

export default router
